import * as firebase from 'firebase/app'
import 'firebase/auth'
// import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  // authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  // signInWithRedirect: is not working with localhost anymore --> use signInWithPopup on localhost
  authDomain: window.location.hostname === 'localhost' ? process.env.VUE_APP_FIREBASE_AUTHDOMAIN : window.location.hostname,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID
}

firebase.initializeApp(firebaseConfig)

const firebaseAuth = firebase.auth()
const firebaseStorage = firebase.storage()

export { firebase, firebaseAuth, firebaseStorage }
